import React from "react"
import addToMailchimp from  'gatsby-plugin-mailchimp'

// This signup form is for in blog posts
class SubstackChange1 extends React.Component{

    // this sign up will change within all blog posts it's placed in
    
    render(){
        return(
            <>
            <i style= {{justifyContent:"center", margin: "auto"}}> 🔥 Enjoying reading this? If you'd like to receive exclusive early access to new posts, along with reading recommendations and other insights, consider providing your email below. I don't spam and will be releasing writings on <strong>how to be a better leader</strong> in the coming months.</i>
            <iframe src="https://seekingclarity.substack.com/embed" width="480" height="120" style= {{paddingTop: "0", paddingBottom: "1.5em", justifyContent:"center", margin: "auto", display:"flex", border: 'none',background: 'white', frameborder: "0", scrolling:"no"}}></iframe>

            </>
        )

        
    }
}

export default SubstackChange1