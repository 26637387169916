import React from "react"
import addToMailchimp from  'gatsby-plugin-mailchimp'

class SubstackSolo1 extends React.Component{
    // this sign up is for bottom of blog post + within blog posts with custom never changing messages
    render(){
        return(
            <>

            <iframe src="https://seekingclarity.substack.com/embed" width="480" height="120" style= {{paddingTop: "0", paddingBottom: "3em", marginRight: "2em", paddingRight: "12em", border: 'none',background: 'white', frameborder: "0", scrolling:"no"}}></iframe>

            </>
        )
    }
}

export default SubstackSolo1